<script setup lang="ts">
import { XIcon } from "@ainform/irbis-ui-storybook";
import { useToggle } from "@vueuse/core";

interface IProps {
  size?: number;
  color?: string;
  hoverColor?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  size: 14,
  color: "#808080",
  hoverColor: "#404040",
});

const [hover, setHover] = useToggle();
const currentColor = computed(() =>
  hover.value ? props.hoverColor : props.color,
);
</script>

<template>
  <button
    :class="$style.closeButton"
    type="button"
    @mouseenter="setHover(true)"
    @mouseleave="setHover(false)"
  >
    <XIcon :color="currentColor" :height="size" :width="size" />
  </button>
</template>

<style module lang="scss">
.closeButton {
  padding: 0;
  background-color: inherit;
  border: none;
  line-height: 0;
}
</style>
