import type { RouteLocationNamedRaw } from "vue-router";

export type QueryType = "universal-search" | "advanced-search";

export interface INavLink {
  id: number;
  title: string;
  href?: string;
  to?: RouteLocationNamedRaw;
}
export type FeaturesType = {
  [key: string]: string[];
};
export enum COURT_TYPE {
  MC = "Районный суд",
  RC = "Мировой суд",
  OBC = "Верховный суд",
  WC = "Военный суд",
  OC = "Кассационный суд",
}

export enum CASE_TYPE {
  G = "Гражданское",
  A = "Административное",
  U = "Уголовное",
  O = "Прочее",
  M = "Производство по материалам",
}

export class CaseParams {
  type: CASE_TYPE[] = [];
  judge: string | null = null;
  category: string | null = null;
  article: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  number: string | null = null;
  status: string | null = null;
  resolutionText: string | null = null;
}

export class CourtParams {
  type: COURT_TYPE | null = null;
  name: string | null = null;
  region: string | null = null;
}

export interface AdvancedQueryParams {
  text: string;
  caseParams: CaseParams;
  courtParams: CourtParams;
}

export enum COURTS_PARTICIPANT {
  DEFENDANT = "DEFENDANT",
  PLAINTIFF = "PLAINTIFF",
  SIDE = "SIDE",
  OTHER = "OTHER",
}

export type CardsStyle = "table" | "cards";
export type CardsViewStyle = "full" | "short";

export enum LOADING_STATUS {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  DONE = "DONE",
  ERROR = "ERROR",
}
