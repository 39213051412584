<script setup lang="ts">
import CheckSelectorItem from "@/components/CheckSelector/CheckSelectorItem.vue";
import { useRoute, type RouteLocationRaw } from "vue-router";
import type { QueryType } from "@/shared/utils/types";

interface ICheckSelectorItemProps {
  title: string;
  queryType: QueryType;
  to: RouteLocationRaw;
}

const checkNavItems: ICheckSelectorItemProps[] = [
  {
    title: "Основной поиск",
    queryType: "universal-search",
    to: { name: "universal-search-check" },
  },
  {
    title: "Расширенный поиск",
    queryType: "advanced-search",
    to: { name: "advanced-search-check" },
  },
];
const route = useRoute();
const selectedQueryType = computed(
  () => (route.meta.queryType as QueryType) ?? "universal-search",
);
</script>

<template>
  <div :class="$style.buttons">
    <CheckSelectorItem
      v-for="item in checkNavItems"
      :key="item.queryType"
      :to="item.to"
      :title="item.title"
      :is-active="item.queryType === selectedQueryType"
    />
  </div>
</template>

<style module lang="scss">
.buttons {
  @include flex(normal, center);
  gap: 14px;
  @media (max-width: $pageWidthXs) {
    justify-content: space-between;
    > * {
      flex: 1;
    }
  }
}
</style>
