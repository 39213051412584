<script setup lang="ts">
import AppHeaderNav from "@/components/layout/AppHeader/AppHeaderNav.vue";
import AppHeaderLogo from "@/components/layout/AppHeader/AppHeaderLogo.vue";
import AppHeaderUser from "@/components/layout/AppHeader/AppHeaderUser/AppHeaderUser.vue";
import AppHeaderHamburger from "./AppHeaderHamburger.vue";
import { useMediaQuery } from "@vueuse/core";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";
import type { INavLink } from "@/shared/utils/types";

const isMobile = useMediaQuery("(max-width: 900px)");
const isSmallMobile = useMediaQuery("(max-width: 520px)");
const route = useRoute();
const { isAdmin } = storeToRefs(useAuthStore());

const navItems = computed<INavLink[]>(() => {
  const base: INavLink[] = [
    { id: 2, to: { name: "universal-search-check" }, title: "Сервис" },
    { id: 3, to: { name: "home", hash: "#tariff" }, title: "Тарифы" },
    { id: 4, to: { name: "home", hash: "#demo" }, title: "Демо" },
    { id: 5, to: { name: "home", hash: "#contact" }, title: "Контакты" },
  ];
  if (route.name !== "home") {
    base.unshift({
      id: 1,
      to: { name: "home" },
      title: "Главная",
    });
  }
  if (isAdmin.value) {
    base.push({
      id: 6,
      to: { name: "panel" },
      title: "Администрирование",
    });
  }
  return base;
});
</script>

<template>
  <header :class="$style.header">
    <div :class="$style.wrapper">
      <div :class="$style.container">
        <div :class="$style.leftPart">
          <AppHeaderHamburger
            v-show="isMobile"
            :is-mobile="isSmallMobile"
            :nav-items="navItems"
          />
          <AppHeaderLogo />
        </div>
        <AppHeaderNav :class="$style.headerMenu" :nav-items="navItems" />
        <AppHeaderUser />
      </div>
    </div>
    <div id="hDropDown" />
  </header>
</template>

<style lang="scss" module>
.header {
  height: 50px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 0 20px;
  @media (max-width: $pageWidthL) {
    z-index: 5;
    position: sticky;
    top: 0;
  }
}
.wrapper {
  height: 100%;
  max-width: $pageWidthL;
  width: calc(100% - 40px);
  margin: auto;
  @media (max-width: $pageWidthXs) {
    position: absolute;
    z-index: 74;
  }
}
.container {
  @include flex(space-between, center);
  height: 100%;
  margin: auto;
}
.leftPart {
  display: flex;
  gap: 15px;
  align-items: center;
}
.headerMenu {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
