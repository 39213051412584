import type { MergeHead, UseHeadInput } from "@unhead/vue";

export type MetaRoutes =
  | "home"
  | "login"
  | "panel"
  | "universal-search-check"
  | "advanced-search-check";

const defaultMeta: UseHeadInput<MergeHead> = {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
};

export const meta = new Map<MetaRoutes, UseHeadInput<MergeHead>>();
meta.set("home", {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
  meta: [
    {
      name: "description",
      content:
        "Найти судебное дело по фамилии и решение суда в 1 клик. Программа для юристов: анализ судов общей юрисдикции. База всех судебных дел СОЮ России. Проверьте ход дела за секунды.",
    },
    {
      name: "keywords",
      content:
        "иск судов, судебные дела по фамилии, найти судебный приказ, поиск судебных дел, по для юриста, база судов, сайты поиска судов, банк судебных решений, подготовка к судебному заседанию",
    },
  ],
});
meta.set("login", {
  title:
    "ИРБИС.ПРАКТИКА. Вход в личный кабинет. Сервис анализа судов для юриста.",
  meta: [
    {
      name: "description",
      content:
        "Легкий поиск судебных дел и анализ практики судов России. Статистика по решениям судьи для подготовки к судебному заседанию. Отслеживайте движение дела в суде.",
    },
    {
      name: "keywords",
      content:
        "аналитика судов, решение суда по делу, поиск по судам, поиск по решениям судов",
    },
  ],
});
meta.set("panel", defaultMeta);
meta.set("universal-search-check", defaultMeta);
meta.set("advanced-search-check", defaultMeta);
