<script setup lang="ts">
import { RouterView } from "vue-router";
import AppLayout from "@/components/layout/AppLayout.vue";
import "@/app/styles/global.scss";
import "@ainform/irbis-ui-storybook/dist/style.css";
import { meta } from "@/app/meta";
import { useHead } from "@unhead/vue";

useHead(meta.get("home")!);
</script>

<template>
  <AppLayout>
    <RouterView />
  </AppLayout>
</template>
