<script setup lang="ts">
import { useTemplateRef } from "vue";
import type { INavLink } from "@/shared/utils/types";
import { autoUpdate, useFloating } from "@floating-ui/vue";
import { vOnClickOutside } from "@vueuse/components";
import { useScrollLock, useSwipe } from "@vueuse/core";
import AppHeaderMobileDropdownList from "./AppHeaderMobileDropdownList.vue";

interface IProps {
  items: INavLink[];
  open: boolean;
  offsetValue?: number;
}
interface IEmits {
  (e: "toggle", value: boolean): void;
}
const props = defineProps<IProps>();
const emits = defineEmits<IEmits>();

const refer = useTemplateRef<HTMLDivElement>("refer");
const float = useTemplateRef<HTMLUListElement>("float");
const swipeBtn = useTemplateRef<HTMLButtonElement>("swipeBtn");

const scrollLocker = useScrollLock(window);
const { floatingStyles } = useFloating(refer, float, {
  transform: false,
  whileElementsMounted: autoUpdate,
});

const { lengthY, isSwiping } = useSwipe(float, {
  onSwipe: () => {
    top.value = lengthY.value >= 0 ? lengthY.value : 0;
  },
  onSwipeEnd: () => {
    const value = lengthY.value / (float.value?.offsetHeight ?? 1);
    const trigger = value < 0.5;
    emits("toggle", trigger);
    top.value = 0;
  },
});

const top = ref(0);
const styles = computed(() => ({
  ...floatingStyles.value,
  zIndex: 72,
  left: 0,
  top: 50 - top.value + "px",
  transition: !isSwiping.value ? "all 0.3s ease-out" : undefined,
}));

const handleClose = () => emits("toggle", false);

const handleToggle = (value: boolean) => {
  emits("toggle", value);
  top.value = 0;
};

watch(
  () => props.open,
  () => {
    scrollLocker.value = props.open;
  },
);
</script>

<template>
  <div v-on-click-outside="handleClose">
    <div ref="refer" :class="$style.trigger" @click="handleToggle(!props.open)">
      <slot name="trigger" />
    </div>
    <Teleport defer to="#hDropDown">
      <Transition name="menu">
        <nav
          v-show="open"
          ref="float"
          :style="styles"
          :class="$style.listWrapper"
        >
          <AppHeaderMobileDropdownList
            v-memo="items"
            :items="items"
            :show-last-item="!!$slots.lastItem"
          >
            <template #lastItem>
              <slot name="lastItem" />
            </template>
          </AppHeaderMobileDropdownList>
          <button
            ref="swipeBtn"
            type="button"
            :class="$style.swipeButtonWrapper"
          >
            <div :class="$style.swipeButton" />
          </button>
        </nav>
      </Transition>
      <Transition>
        <div v-show="open" :class="$style.background" />
      </Transition>
    </Teleport>
  </div>
</template>

<style lang="scss" module>
.trigger {
  line-height: 0;
}

.listWrapper {
  width: 100%;
  background-color: $white;
  padding: 18px 0 8px;
  border-radius: 0 0 20px 20px;
  text-align: center;
}
.background {
  width: 100%;
  height: 100lvh;
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  background-color: $black;
  opacity: 0.2;
  z-index: 70;
}

.swipeButtonWrapper {
  border: none;
  background-color: inherit;
}

.swipeButton {
  border-radius: 3px;
  background-color: #ccc;
  margin: 12px 0 0;
  height: 6px;
  width: 50px;
}
</style>

<style>
.menu-enter-active,
.menu-leave-active {
  transition: all 0.3s ease-out;
}

.menu-enter-from,
.menu-leave-to {
  transform: translateY(-300px);
  opacity: 0;
}
</style>
