import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";
import axios from "axios";

const MainPage = () => import("@/pages/main-page/MainPage.vue");
const LoginPage = () => import("@/pages/login/LoginPage.vue");
const UniJudgePage = () => import("@/pages/uni-judge-page/UniJudgePage.vue");
const AdvancedJudgePage = () =>
  import("@/pages/advanced-judge-page/AdvancedJudgePage.vue");
const AdminPage = () => import("@/pages/admin/AdminPage.vue");

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, _, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: MainPage,
    },
    {
      path: "/panel",
      name: "panel",
      component: AdminPage,
    },
    {
      path: "/service",
      beforeEnter: () => {
        const authStore = useAuthStore();
        if (!authStore.credentials.authenticated) {
          return { name: "login" };
        }
        return true;
      },
      redirect() {
        return { name: "universal-search-check" };
      },
      children: [
        {
          path: "/universal-search",
          name: "universal-search-check",
          component: UniJudgePage,
          meta: {
            queryType: "universal-search",
          },
        },
        {
          path: "/advanced-search",
          name: "advanced-search-check",
          component: AdvancedJudgePage,
          meta: {
            queryType: "advanced-search",
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
  ],
});

// автоматическая перезагрузка после выпуска новой версии
router.beforeEach(async (to, from) => {
  if (import.meta.env.MODE === "development") return;
  if (to.fullPath === from.fullPath) return;
  let reloadTryCount = Number(sessionStorage.getItem("reloadTryCount")) || 0;
  if (reloadTryCount > 3) return;
  const response = await axios.get<{ version: string }>(
    "/latest-version-praktika.json",
  );
  if (response.data?.version === window.APP_VERSION) {
    sessionStorage.setItem("reloadTryCount", "0");
    return;
  }
  reloadTryCount++;
  sessionStorage.setItem("reloadTryCount", reloadTryCount.toString());
  window.open(to.fullPath, "_self");
});

export default router;
