<script setup lang="ts">
import defaultUserIcon from "@/shared/assets/irbis-user.svg";
import { ChevronIcon, DropDown } from "@ainform/irbis-ui-storybook";
import { useToggle } from "@vueuse/core";
import AppHeaderButtons from "@/components/layout/AppHeader/AppHeaderUser/AppHeaderButtons.vue";
import logoutSvg from "@/shared/assets/icons/logout.svg";
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";

const [open, setOpen] = useToggle();

const handleToggle = () => setOpen(!open.value);
const handleClose = () => setOpen(false);
const authStore = useAuthStore();
const { logout } = authStore;
const { credentials } = storeToRefs(authStore);
</script>

<template>
  <DropDown
    v-if="credentials.authenticated"
    :visible="open"
    :offset="10"
    :content-class="$style.dropdown"
    @close="handleClose"
    @toggle="handleToggle"
  >
    <template #trigger>
      <button :class="$style.user">
        <span :class="$style.userIconWrapper">
          <img
            :class="$style.userIcon"
            :src="defaultUserIcon"
            alt="user icon"
          />
        </span>
        <span :class="$style.username">{{ credentials.username }}</span>
        <span>
          <ChevronIcon
            color="#3b50a8"
            :width="10"
            :height="10"
            :rotation-angle="90"
            :active="open"
          />
        </span>
      </button>
    </template>
    <template #content>
      <button :class="$style.logoutButtonWrapper">
        <div :class="$style.logoutButton" @click="logout">Выйти</div>
        <img :src="logoutSvg" alt="logout" />
      </button>
    </template>
  </DropDown>
  <AppHeaderButtons v-else />
</template>

<style lang="scss" module>
.user {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background-color: inherit;
  text-align: left;
  height: 50px;
  &:hover {
    background-color: rgba(170, 188, 255, 0.1);
  }
  &:focus {
    outline: none;
    background-color: rgba(170, 188, 255, 0.1);
  }
}
.username {
  @media (max-width: $pageWidthXs) {
    display: none;
  }
}
.userIconWrapper {
  display: inline-block;
  border-radius: 50%;
}
.userIcon {
  width: 35px;
  height: 35px;
}
.dropdown {
  font-size: 14px;
  border-radius: 3px !important;
  padding: 11px 0 !important;
  box-shadow: 0 1px 7.84px 0.16px rgba(0, 0, 0, 0.1) !important;
}
.logoutButtonWrapper {
  padding: 6px 20px;
  background: inherit;
  border: none;
  @include flex(space-between, center);
  &:hover {
    background-color: rgba(170, 188, 255, 0.1);
    color: #5c5c5c;
  }
}
.logoutButton {
  margin-right: 14px;
}
</style>
