import axios from "axios";
import { useRouter } from "vue-router";
import { type LoginResponse, USER_ROLES } from "@/app/api/types";
import { useSessionStorage } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";
import { LOADING_STATUS } from "@/shared/utils/types";

export const useAuthStore = defineStore("auth", function () {
  const username = ref("");
  const password = ref("");
  const credentials = ref<LoginResponse>({ authenticated: false, roles: [] });
  const isAdmin = ref(false);
  const status = ref(LOADING_STATUS.INITIAL);

  const credentialsStorage = useSessionStorage<LoginResponse>("authenticated", {
    authenticated: false,
    roles: [],
  });

  if (credentialsStorage.value.authenticated) {
    credentials.value = credentialsStorage.value;
  }

  const router = useRouter();

  async function login() {
    status.value = LOADING_STATUS.LOADING;
    await axios
      .get<LoginResponse>("/api/v1/user", {
        auth: { username: username.value, password: password.value },
      })
      .then((r) => {
        status.value = LOADING_STATUS.DONE;
        credentials.value = r.data;
        credentialsStorage.value = r.data;
        if (credentials.value.authenticated) {
          router.push("/universal-search");
        }
        if (credentials.value.roles.includes(USER_ROLES.ADMIN)) {
          isAdmin.value = true;
        }
      })
      .catch(() => {
        status.value = LOADING_STATUS.ERROR;
      });
  }

  async function logout() {
    await axios.post("/logout");
    credentials.value = { authenticated: false, roles: [] };
    credentialsStorage.value = { authenticated: false, roles: [] };
    isAdmin.value = false;
    await router.push({ name: "login" });
  }

  const restoreSession = async () => {
    const cookies = useCookies(["XSRF-TOKEN"]);
    const token = cookies.get("XSRF-TOKEN");

    if (!token) {
      credentialsStorage.value = { authenticated: false, roles: [] };
    }
    if (credentials.value.roles.includes(USER_ROLES.ADMIN)) {
      isAdmin.value = true;
    }
    credentials.value = credentialsStorage.value;
  };

  return {
    username,
    password,
    credentials,
    isAdmin,
    status,
    login,
    logout,
    restoreSession,
  };
});
