<script setup lang="ts">
import { IrbisLink, LinkSkin } from "@ainform/irbis-ui-storybook";
import { type RouteLocationRaw, useRouter } from "vue-router";

export interface IProps {
  title: string;
  isActive: boolean;
  to: RouteLocationRaw;
}
const props = defineProps<IProps>();
const styles = useCssModule();

const buttonClasses = computed(() => [
  styles.checkButton,
  props.isActive && styles.selected,
]);
const router = useRouter();

const handleClick = (e: Event) => {
  e.preventDefault();
  router.push(props.to);
};
</script>

<template>
  <IrbisLink
    :skin="LinkSkin.EMPTY"
    :to="to"
    :class="[$style.optionButton, buttonClasses]"
    @click="handleClick"
  >
    {{ title }}
  </IrbisLink>
</template>

<style module lang="scss">
.checkButton {
  background: rgba(140, 138, 250, 0.1);
  border: none;
  border-radius: 4px;
  color: #42389d;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  height: 100%;
  &:hover {
    background: rgba(140, 138, 250, 0.2);
    color: #42389d;
    cursor: pointer;
  }
  @include media($pageWidthXs) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
  }
  @media (max-width: 370px) {
    height: 48px;
  }
}
.selected {
  color: #fff;
  background: #42389d;
  &:hover {
    background: #42389d;
    color: #fff;
  }
}
</style>
